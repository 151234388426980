import React, {useEffect, useState} from "react"
import { Col, Container, Row } from "react-bootstrap";

// Styles
import './CharitiesWeSupport.scss';

// Components
import SectionWrapper from "components/SectionWrapper";
import SectionHeader from "components/SectionHeader";
import CustomButton from "components/CustomButton";

import {ImageModule} from '../../../modules/Image_Module'

const postsPerPage = 20;
let arrayForHoldingPosts = [];

const CharitiesWeSupport = ( { Module } ) => {

    const sectionHeaderData = {
        Title: Module.Title
    };

    const [postsToShow, setPostsToShow] = useState([]);
    const [logoList, setLogoList] = useState(Module?.Brand_Logo_Module);
    const [next, setNext] = useState(postsPerPage);

    const loopWithSlice = (start, end) => {
        const sliced_Posts = logoList.slice(start, end);
        arrayForHoldingPosts = [...arrayForHoldingPosts, ...sliced_Posts];
        setPostsToShow(arrayForHoldingPosts);
    };

    const handleShowMorePosts = () => {
        loopWithSlice(next, next + postsPerPage);
        setNext(next + postsPerPage);
    };

    useEffect(() => {
        loopWithSlice(0, postsPerPage);
    }, [logoList]);

    return (
        <SectionWrapper
            className="charities-we-support-wrapper"
            id="charitiesWeSupport"
        >
            {postsToShow && postsToShow.length > 0 &&
                <Container>
                    <div className="charities-we-support-header">
                        <SectionHeader
                            sectionHeaderData={ sectionHeaderData }
                        />
                    </div>
                    <div className="charities-we-support-body">
                        <Row className="charity-logos">
                            {
                                postsToShow?.map( ( charityLogoItem, charityLogoIndex ) => {
                                    return (
                                        <Col
                                            lg="3"
                                            sm="6"
                                            className={ charityLogoIndex > 11 ? 'hide-on-tab-and-mobile' : '' }
                                        >
                                            <div className="charity-logo">
                                                <a href={charityLogoItem.Link} target="_blank"><ImageModule ImageSrc={charityLogoItem.Image} /></a>
                                            </div>
                                        </Col>
                                    )
                                } )
                            }
                        </Row>
                        
                        {next < logoList.length && 
                        <div className="load-more-button">
                            <CustomButton
                                variant="outline"
                                onClick={handleShowMorePosts}
                                value="LOAD MORE"
                            />
                        </div>
                        }
                    </div>
                </Container>
            }
        </SectionWrapper>
    )
}

export default CharitiesWeSupport
